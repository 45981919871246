import { mapGetters } from 'vuex';
import { Enums, Providers, Mappers } from '@flightscope/baseball-stats';
import { Session } from '@/models/orm/Hierarchy';
import { conversionType } from '@/plugins/converter';
import ResultFilter from '@/filters/ResultFilter';
import { mapRunnersOnSelectToProvider, sanitizeGuid } from '@/utils/helpers';
import { PitchCallSeriesColorMapping, PitchResultCombinedColorMapping, PitchTypeSeriesColorMapping, QualityOfContactColorMapping, SwingDecisionSeriesColorMapping } from '@/components/ui/charts/ColorMapping';
import HoveredPointTooltip from '@/components/ui/tooltips/HoveredPointTooltip.vue';
import tooltipDataProvider from '@/providers/TooltipDataProvider';
import hoverPointDataProvider from '@/providers/HoverPointDataProvider';
import { unitSymbolProvider } from '@/filters/units';
import { ChartTypeEnum } from '@/components/ui/charts/ChartHelpers';
import QualityOfContact from '@/enums/QualityOfContact';
import { unitsConverter } from '@/plugins/converter';

import ActionBottomSheet from '../video-library/components/ActionBottomSheet.vue';

// TODO: move to lang file
const translation = {
  no_data_for_filters: 'No data found matching selected filters.',
  loading: 'Data loading in progress...',
};

const definedViews = [
  ChartTypeEnum.SPRAY_CHART.type,
  ChartTypeEnum.SPRAY_CHART_PITCHING.type,
  ChartTypeEnum.STRIKE_ZONE.type,
  ChartTypeEnum.BATTER_PERFORMANCE.type,
  ChartTypeEnum.PITCHER_PERFORMANCE.type,
  ChartTypeEnum.RELEASE_POINT.type,
  ChartTypeEnum.PITCH_LOCATION.type,
  ChartTypeEnum.RELEASE_EXTENSION.type,
  ChartTypeEnum.PITCH_MOVEMENT.type,
  ChartTypeEnum.BATTED_BALL_QUALITY.type,
  ChartTypeEnum.CONTACT_REPORT.type,
  ChartTypeEnum.CONTACT_REPORT_PITCHING.type,
];

export default {
  name: 'SessionChart',

  components: {
    HoveredPointTooltip,
    ActionBottomSheet,
  },

  seriesSI: null,

  inject: ['repo'],

  provide() {
    return {
      context: this.$route.params.context,
      shareEnabled: !!process.env.VUE_APP_SHARE_ENABLED,
    };
  },

  props: {
    session: {
      type: Session,
      required: true,
    },

    hideIfEmpty: Boolean,

    hideTable: Boolean,

    static: Boolean,
    viewNameProp: {
      type: String,
      default: undefined,
    },

    playerID: {
      type: Number,
    },

    additionalLayout: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      loading: true,
      hasSeries: false,
      providerCounter: 0,
      pointData: {},
      xConversion: conversionType.NUMBER,
      yConversion: conversionType.NUMBER,
      xPrecision: 2,
      yPrecision: 2,

      deletedVideoIds: [],
    };
  },

  computed: {
    ...mapGetters(['selectedUnitsSystem']),

    t() {
      return translation;
    },

    /**
     * @returns {string}
     */
    viewName() {
      // TODO: better way than a static index needed
      return this.viewNameProp || this.$route.name.split('.')[3];
    },

    storeKey() {
      let key = '';

      switch (this.viewName) {
        case ChartTypeEnum.SPRAY_CHART.type:
          key = ChartTypeEnum.SPRAY_CHART.storeKey;
          break;

        case ChartTypeEnum.SPRAY_CHART_PITCHING.type:
          key = ChartTypeEnum.SPRAY_CHART_PITCHING.storeKey;
          break;

        case ChartTypeEnum.STRIKE_ZONE.type:
          key = ChartTypeEnum.STRIKE_ZONE.storeKey;
          break;

        case ChartTypeEnum.BATTER_PERFORMANCE.type:
          key = ChartTypeEnum.BATTER_PERFORMANCE.storeKey;
          break;

        case ChartTypeEnum.PITCHER_PERFORMANCE.type:
          key = ChartTypeEnum.PITCHER_PERFORMANCE.storeKey;
          break;

        case ChartTypeEnum.RELEASE_POINT.type:
          key = ChartTypeEnum.RELEASE_POINT.storeKey;
          break;

        case ChartTypeEnum.PITCH_LOCATION.type:
          key = ChartTypeEnum.PITCH_LOCATION.storeKey;
          break;

        case ChartTypeEnum.RELEASE_EXTENSION.type:
          key = ChartTypeEnum.RELEASE_EXTENSION.storeKey;
          break;

        case ChartTypeEnum.PITCH_MOVEMENT.type:
          key = ChartTypeEnum.PITCH_MOVEMENT.storeKey;
          break;

        case ChartTypeEnum.BATTED_BALL_QUALITY.type:
          key = ChartTypeEnum.BATTED_BALL_QUALITY.storeKey;
          break;

        case ChartTypeEnum.CONTACT_REPORT.type:
          key = ChartTypeEnum.CONTACT_REPORT.storeKey;
          break;

        case ChartTypeEnum.CONTACT_REPORT_PITCHING.type:
          key = ChartTypeEnum.CONTACT_REPORT_PITCHING.storeKey;
          break;

        default:
          // TODO: maybe change to NOOP after implementation
          throw new Error('not implemented');
      }
      return key;
    },

    storeFilters() {
      if (this.session) {
        return {
          ...this.session.filters.global,
          ...this.session.filters[this.storeKey],
        };
      }
      return {};
    },

    mappedFilters() {
      let filters = {};
      switch (this.viewName) {
        case ChartTypeEnum.SPRAY_CHART.type:
          filters = {
            batterIds: this.playerID || this.storeFilters.batter || 0,
            pitchResults: this.storeFilters[ResultFilter.PitchResult.key] || [],
            pitchTypes: this.storeFilters[ResultFilter.PitchType.key] || [],
            zones: this.storeFilters.zones || [],
            pitcherIds: this.storeFilters.pitchers || [],
            pitcherHandedness: this.storeFilters[ResultFilter.PitcherHandedness.key] || '',
          };


          // TODO: later be sure it works properly also with mightHaveNewScoringData
          if (this.session && this.session.hasExtendedTagging) {
            filters = {
              ...filters,
              pitchResults: this.storeFilters[ResultFilter.PlayOutcome.key] || [],
            };
          }

          if (this.session && this.session.mightHaveScoring) {
            filters = {
              ...filters,
              outs: this.storeFilters[ResultFilter.ScoringOuts.key] || null,
              balls: this.storeFilters[ResultFilter.ScoringBalls.key] || null,
              strikes: this.storeFilters[ResultFilter.ScoringStrikes.key] || null,
              runnersOn: mapRunnersOnSelectToProvider(this.storeFilters[ResultFilter.ScoringRunnersOn.key]),
            };
          }
          break;

        case ChartTypeEnum.SPRAY_CHART_PITCHING.type:
          filters = {
            pitcherIds: [this.playerID || this.storeFilters.pitchers || 0],
            pitchResults: this.storeFilters[ResultFilter.PitchResult.key] || [],
            pitchTypes: this.storeFilters[ResultFilter.PitchType.key] || [],
            zones: this.storeFilters.zones || [],
            batterIds: this.storeFilters.pitchers || [],
            pitcherHandedness: this.storeFilters[ResultFilter.PitcherHandedness.key] || '',
          };

          if (this.session && this.session.hasExtendedTagging) {
            filters = {
              ...filters,
              pitchResults: this.storeFilters[ResultFilter.PlayOutcome.key] || [],
            };
          }

          if (this.session && this.session.mightHaveScoring) {
            filters = {
              ...filters,
              outs: this.storeFilters[ResultFilter.ScoringOuts.key] || null,
              balls: this.storeFilters[ResultFilter.ScoringBalls.key] || null,
              strikes: this.storeFilters[ResultFilter.ScoringStrikes.key] || null,
              runnersOn: mapRunnersOnSelectToProvider(this.storeFilters[ResultFilter.ScoringRunnersOn.key]),
            };
          }
          break;

        case ChartTypeEnum.STRIKE_ZONE.type:
          filters = {
            batterId: this.playerID || this.storeFilters[ResultFilter.Batter.key] || 0,
            pitchResults: this.storeFilters[ResultFilter.PitchResult.key] || [],
            pitchTypes: this.storeFilters[ResultFilter.PitchType.key] || [],
            pitcherIds: this.storeFilters[ResultFilter.Pitchers.key] || [],
            pitcherHandedness: this.storeFilters[ResultFilter.PitcherHandedness.key] || '',
            zones: this.storeFilters[ResultFilter.Zones.key] || [],
          };

          if (this.session && this.session.hasExtendedTagging) {
            filters = {
              ...filters,
              pitchResults: this.storeFilters[ResultFilter.PlayOutcome.key] || [],
            };
          }

          if (this.session && this.session.mightHaveScoring) {
            filters = {
              ...filters,
              outs: this.storeFilters[ResultFilter.ScoringOuts.key] || null,
              balls: this.storeFilters[ResultFilter.ScoringBalls.key] || null,
              strikes: this.storeFilters[ResultFilter.ScoringStrikes.key] || null,
              runnersOn: mapRunnersOnSelectToProvider(this.storeFilters[ResultFilter.ScoringRunnersOn.key]),
            };
          }
          break;

        case ChartTypeEnum.BATTER_PERFORMANCE.type:
          filters = {
            batterId: this.playerID || this.storeFilters.batter || 0,
            pitchResults: this.storeFilters[ResultFilter.PitchResult.key],
            pitchTypes: this.storeFilters[ResultFilter.PitchType.key],
            pitcherIds: this.storeFilters.pitchers,
            pitcherHandedness: this.storeFilters[ResultFilter.PitcherHandedness.key],
            hitParameter: this.storeFilters.hitParameter,
          };

          if (process.env.VUE_APP_SPORT_TYPE == Enums.SportType.Baseball.key) {
            filters = {
              ...filters,
              pitchSet: this.storeFilters.zone,
            };
          }

          if (this.session && this.session.mightHaveScoring) {
            filters = {
              ...filters,
              outs: this.storeFilters[ResultFilter.ScoringOuts.key] || null,
              balls: this.storeFilters[ResultFilter.ScoringBalls.key] || null,
              strikes: this.storeFilters[ResultFilter.ScoringStrikes.key] || null,
              runnersOn: mapRunnersOnSelectToProvider(this.storeFilters[ResultFilter.ScoringRunnersOn.key]),
            };
          }
          break;

        case ChartTypeEnum.PITCHER_PERFORMANCE.type:
          filters = {
            pitcherId: this.playerID || this.storeFilters.pitcher || 0,
            pitchResults: this.storeFilters[ResultFilter.PitchResult.key],
            pitchTypes: this.storeFilters[ResultFilter.PitchType.key],
            batterIds: this.storeFilters.batters,
            batterHandedness: this.storeFilters[ResultFilter.BatterHandedness.key],
            pitchParameter: this.storeFilters.pitchParameter,
          };

          if (process.env.VUE_APP_SPORT_TYPE == Enums.SportType.Baseball.key) {
            filters = {
              ...filters,
              pitchSet: this.storeFilters[ResultFilter.PitchSet.key] || '',
            };
          }

          if (this.session && this.session.hasExtendedTagging) {
            filters = {
              ...filters,
              pitchResults: this.storeFilters[ResultFilter.PlayOutcome.key] || [],
            };
          }

          if (this.session && this.session.mightHaveScoring) {
            filters = {
              ...filters,
              outs: this.storeFilters[ResultFilter.ScoringOuts.key] || null,
              balls: this.storeFilters[ResultFilter.ScoringBalls.key] || null,
              strikes: this.storeFilters[ResultFilter.ScoringStrikes.key] || null,
              runnersOn: mapRunnersOnSelectToProvider(this.storeFilters[ResultFilter.ScoringRunnersOn.key]),
            };
          }
          break;

        case ChartTypeEnum.RELEASE_POINT.type:
          filters = {
            pitcherId: this.playerID || this.storeFilters.pitcher || 0,
            pitchResults: this.storeFilters[ResultFilter.PitchResult.key] || [],
            pitchTypes: this.storeFilters[ResultFilter.PitchType.key] || [],
            batterIds: this.storeFilters.batters || [],
            batterHandedness: this.storeFilters[ResultFilter.BatterHandedness.key] || '',
          };

          if (process.env.VUE_APP_SPORT_TYPE == Enums.SportType.Baseball.key) {
            filters = {
              ...filters,
              pitchSet: this.storeFilters[ResultFilter.PitchSet.key] || '',
            };
          }

          if (this.session && this.session.hasExtendedTagging) {
            filters = {
              ...filters,
              pitchResults: this.storeFilters[ResultFilter.PlayOutcome.key] || [],
            };
          }

          if (this.session && this.session.mightHaveScoring) {
            filters = {
              ...filters,
              outs: this.storeFilters[ResultFilter.ScoringOuts.key] || null,
              balls: this.storeFilters[ResultFilter.ScoringBalls.key] || null,
              strikes: this.storeFilters[ResultFilter.ScoringStrikes.key] || null,
              runnersOn: mapRunnersOnSelectToProvider(this.storeFilters[ResultFilter.ScoringRunnersOn.key]),
            };
          }
          break;

        case ChartTypeEnum.PITCH_LOCATION.type:
          filters = {
            pitcherId: this.playerID || this.storeFilters[ResultFilter.Pitcher.key] || 0,
            pitchResults: this.storeFilters[ResultFilter.PitchResult.key] || [],
            pitchTypes: this.storeFilters[ResultFilter.PitchType.key] || [],
            batterIds: this.storeFilters[ResultFilter.Batters.key] || [],
            batterHandedness: this.storeFilters[ResultFilter.BatterHandedness.key] || '',
            zones: this.storeFilters[ResultFilter.Zones.key] || [],
          };

          if (process.env.VUE_APP_SPORT_TYPE == Enums.SportType.Baseball.key) {
            filters = {
              ...filters,
              pitchSet: this.storeFilters[ResultFilter.PitchSet.key] || '',
            };
          }

          if (this.session && this.session.hasExtendedTagging) {
            filters = {
              ...filters,
              pitchResults: this.storeFilters[ResultFilter.PlayOutcome.key] || [],
            };
          }

          if (this.session && this.session.mightHaveScoring) {
            filters = {
              ...filters,
              outs: this.storeFilters[ResultFilter.ScoringOuts.key] || null,
              balls: this.storeFilters[ResultFilter.ScoringBalls.key] || null,
              strikes: this.storeFilters[ResultFilter.ScoringStrikes.key] || null,
              runnersOn: mapRunnersOnSelectToProvider(this.storeFilters[ResultFilter.ScoringRunnersOn.key]),
            };
          }
          break;

        case ChartTypeEnum.RELEASE_EXTENSION.type:
          filters = {
            pitcherId: this.playerID || this.storeFilters.pitcher || 0,
            pitchResults: this.storeFilters[ResultFilter.PitchResult.key] || [],
            pitchTypes: this.storeFilters[ResultFilter.PitchType.key] || [],
            batterIds: this.storeFilters.batters || [],
            batterHandedness: this.storeFilters[ResultFilter.BatterHandedness.key] || '',
          };

          if (process.env.VUE_APP_SPORT_TYPE == Enums.SportType.Baseball.key) {
            filters = {
              ...filters,
              pitchSet: this.storeFilters[ResultFilter.PitchSet.key] || '',
            };
          }

          if (this.session && this.session.hasExtendedTagging) {
            filters = {
              ...filters,
              pitchResults: this.storeFilters[ResultFilter.PlayOutcome.key] || [],
            };
          }

          if (this.session && this.session.mightHaveScoring) {
            filters = {
              ...filters,
              outs: this.storeFilters[ResultFilter.ScoringOuts.key] || null,
              balls: this.storeFilters[ResultFilter.ScoringBalls.key] || null,
              strikes: this.storeFilters[ResultFilter.ScoringStrikes.key] || null,
              runnersOn: mapRunnersOnSelectToProvider(this.storeFilters[ResultFilter.ScoringRunnersOn.key]),
            };
          }
          break;

        case ChartTypeEnum.PITCH_MOVEMENT.type:
          filters = {
            pitcherId: this.playerID || this.storeFilters.pitcher || 0,
            pitchResults: this.storeFilters[ResultFilter.PitchResult.key] || [],
            pitchTypes: this.storeFilters[ResultFilter.PitchType.key] || [],
            batterIds: this.storeFilters.batters || [],
            batterHandedness: this.storeFilters[ResultFilter.BatterHandedness.key] || '',
          };

          if (process.env.VUE_APP_SPORT_TYPE == Enums.SportType.Baseball.key) {
            filters = {
              ...filters,
              pitchSet: this.storeFilters[ResultFilter.PitchSet.key] || '',
            };
          }

          if (this.session && this.session.hasExtendedTagging) {
            filters = {
              ...filters,
              pitchResults: this.storeFilters[ResultFilter.PlayOutcome.key] || [],
            };
          }

          if (this.session && this.session.mightHaveScoring) {
            filters = {
              ...filters,
              outs: this.storeFilters[ResultFilter.ScoringOuts.key] || null,
              balls: this.storeFilters[ResultFilter.ScoringBalls.key] || null,
              strikes: this.storeFilters[ResultFilter.ScoringStrikes.key] || null,
              runnersOn: mapRunnersOnSelectToProvider(this.storeFilters[ResultFilter.ScoringRunnersOn.key]),
            };
          }
          break;

        case ChartTypeEnum.BATTED_BALL_QUALITY.type:
          filters = {
            batterId: this.playerID || this.storeFilters.batter || 0,
            pitchResults: this.storeFilters[ResultFilter.PitchResult.key] || [],
            pitchTypes: this.storeFilters[ResultFilter.PitchType.key] || [],
            zones: this.storeFilters.zones || [],
            pitcherIds: this.storeFilters.pitchers || [],
            pitcherHandedness: this.storeFilters[ResultFilter.PitcherHandedness.key] || '',
          };

          if (this.session && this.session.hasExtendedTagging) {
            filters = {
              ...filters,
              pitchResults: this.storeFilters[ResultFilter.PlayOutcome.key] || [],
            };
          }

          if (this.session && this.session.mightHaveScoring) {
            filters = {
              ...filters,
              outs: this.storeFilters[ResultFilter.ScoringOuts.key] || null,
              balls: this.storeFilters[ResultFilter.ScoringBalls.key] || null,
              strikes: this.storeFilters[ResultFilter.ScoringStrikes.key] || null,
              runnersOn: mapRunnersOnSelectToProvider(this.storeFilters[ResultFilter.ScoringRunnersOn.key]),
            };
          }
          break;

        case ChartTypeEnum.CONTACT_REPORT.type:
          filters = {
            batterIds: [this.playerID || this.storeFilters[ResultFilter.Batter.key]] || [],
            pitchResults: this.storeFilters[ResultFilter.PitchResult.key] || [],
            pitchTypes: this.storeFilters[ResultFilter.PitchType.key] || [],
            pitcherIds: this.storeFilters[ResultFilter.Pitchers.key] || [],
            pitcherHandedness: this.storeFilters[ResultFilter.PitcherHandedness.key] || '',
            zones: this.storeFilters[ResultFilter.Zones.key] || [],
          };

          if (this.session && this.session.mightHaveScoring) {
            filters = {
              ...filters,
              outs: this.storeFilters[ResultFilter.ScoringOuts.key] || null,
              balls: this.storeFilters[ResultFilter.ScoringBalls.key] || null,
              strikes: this.storeFilters[ResultFilter.ScoringStrikes.key] || null,
              runnersOn: mapRunnersOnSelectToProvider(this.storeFilters[ResultFilter.ScoringRunnersOn.key]),
            };
          }
          break;

        case ChartTypeEnum.CONTACT_REPORT_PITCHING.type:
          filters = {
            pitcherIds: [this.playerID || this.storeFilters[ResultFilter.Batter.key]] || [],
            pitchResults: this.storeFilters[ResultFilter.PitchResult.key] || [],
            pitchTypes: this.storeFilters[ResultFilter.PitchType.key] || [],
            batterIds: this.storeFilters[ResultFilter.Pitchers.key] || [],
            pitcherHandedness: this.storeFilters[ResultFilter.PitcherHandedness.key] || '',
            zones: this.storeFilters[ResultFilter.Zones.key] || [],
          };

          if (this.session && this.session.mightHaveScoring) {
            filters = {
              ...filters,
              outs: this.storeFilters[ResultFilter.ScoringOuts.key] || null,
              balls: this.storeFilters[ResultFilter.ScoringBalls.key] || null,
              strikes: this.storeFilters[ResultFilter.ScoringStrikes.key] || null,
              runnersOn: mapRunnersOnSelectToProvider(this.storeFilters[ResultFilter.ScoringRunnersOn.key]),
            };
          }
          break;

        default:
          // TODO: maybe change to NOOP after implementation
          throw new Error('not implemented');
      }
      return filters;
    },

    chartSettings() {
      let componentName = null;
      let xConversion = this.xConversion;
      let yConversion = this.yConversion;
      let xPrecision = this.xPrecision;
      let yPrecision = this.yPrecision;
      let enumSet = undefined;
      let colorMapper = undefined;
      let enabledHover = undefined;
      let enabledMarkerClick = undefined;
      let component = undefined;
      let seriesMapper = Mappers.SeriesMappers.PlotlyScatterSeriesMapper;
      let xAxisTitle = 'X';
      let yAxisTitle = 'Y';
      let hoverTextFormatter = undefined;

      switch (this.viewName) {
        case ChartTypeEnum.SPRAY_CHART.type:
          componentName = 'SprayChart';
          enumSet = Enums.PitchResultCombined;
          colorMapper = PitchResultCombinedColorMapping;
          enabledHover = true;
          xConversion = conversionType.DISTANCE_SHORT;
          yConversion = conversionType.DISTANCE_SHORT;
          enabledMarkerClick = true;
          seriesMapper = Mappers.SeriesMappers.PlotlyScatterSeriesMapper;
          xAxisTitle = this.$vuetify.lang.t('$vuetify.landingHorizontal');
          yAxisTitle = this.$vuetify.lang.t('$vuetify.landingVertical');
          hoverTextFormatter = (series) => {
            series.forEach(serie => {
              serie.text = serie.customdata.map(resultId => `Carry: ${this.getCarry(resultId)}`)
            });
          }
          break;

        case ChartTypeEnum.SPRAY_CHART_PITCHING.type:
          componentName = 'SprayChart';
          enumSet = Enums.PitchResult;
          colorMapper = PitchCallSeriesColorMapping;
          enabledHover = true;
          xConversion = conversionType.DISTANCE_SHORT;
          yConversion = conversionType.DISTANCE_SHORT;
          enabledMarkerClick = true;
          seriesMapper = Mappers.SeriesMappers.PlotlyScatterSeriesMapper;
          xAxisTitle = this.$vuetify.lang.t('$vuetify.landingHorizontal');
          yAxisTitle = this.$vuetify.lang.t('$vuetify.landingVertical');
          break;

        case ChartTypeEnum.STRIKE_ZONE.type:
          componentName = 'StrikeZone';
          xConversion = Enums.PitchReprocessed.HomePlateSideR.type;
          yConversion = Enums.PitchReprocessed.HomePlateHeightR.type;
          enumSet = Enums.PitchResultCombined;
          colorMapper = PitchResultCombinedColorMapping;
          enabledHover = true;
          enabledMarkerClick = true;
          seriesMapper = Mappers.SeriesMappers.PlotlyScatterSeriesMapper;
          xAxisTitle = this.$vuetify.lang.t('$vuetify.horizontalLocation');
          yAxisTitle = this.$vuetify.lang.t('$vuetify.verticalLocation');
          break;

        case ChartTypeEnum.BATTER_PERFORMANCE.type:
          componentName = 'PerformanceTracking';
          xConversion = conversionType.NUMBER;
          if (typeof this.performanceSettings === 'object') {
            yConversion = this.performanceSettings.yConversion;
          } else {
            yConversion = conversionType.NUMBER;
          }
          enabledHover = true;
          enabledMarkerClick = true;
          xAxisTitle = 'Plate appearance';
          yAxisTitle = this.performanceSettings.dataName;
          enumSet = { ...Enums.HitData, Unidentified: Enums.HitData.ExitSpeed };
          break;

        case ChartTypeEnum.BATTED_BALL_QUALITY.type:
          componentName = 'BattedBallQuality';
          enumSet = QualityOfContact;
          colorMapper = QualityOfContactColorMapping;
          enabledHover = false;
          xConversion = Enums.HitData.LaunchV.type;
          yConversion = Enums.HitData.ExitSpeed.type;
          enabledMarkerClick = false;
          seriesMapper = Mappers.SeriesMappers.PlotlyPolarSeriesMapper;
          break;

        case ChartTypeEnum.PITCHER_PERFORMANCE.type:
          componentName = 'PitchPerformanceTracking';
          xConversion = conversionType.NUMBER;
          if (typeof this.performanceSettings === 'object') {
            yConversion = this.performanceSettings.yConversion;
          } else {
            yConversion = conversionType.NUMBER;
          }
          if (this.session?.filters?.pitch_tracking?.pitchParameter === 'PITCH_EXTENSION') {
            yPrecision = 1;
          }
          enabledHover = true;
          enabledMarkerClick = true;
          xAxisTitle = 'Pitch count';
          yAxisTitle = this.performanceSettings.dataName;
          enumSet = { ...Enums.PitchData, Unidentified: Enums.PitchData.Speed };
          break;

        case ChartTypeEnum.RELEASE_POINT.type:
          componentName = 'ReleasePointChart';
          xConversion = Enums.PitchReprocessed.ReleaseSideR.type;
          yConversion = Enums.PitchReprocessed.ReleaseHeightR.type;
          enumSet = Enums.BaseballPitchType;
          colorMapper = PitchTypeSeriesColorMapping;
          enabledHover = true;
          enabledMarkerClick = true;
          xAxisTitle = this.$vuetify.lang.t('$vuetify.releaseSide');
          yAxisTitle = this.$vuetify.lang.t('$vuetify.releaseHeight');
          break;

        case ChartTypeEnum.PITCH_LOCATION.type:
          componentName = 'PitchLocation';
          xConversion = Enums.PitchReprocessed.HomePlateSideR.type;
          yConversion = Enums.PitchReprocessed.HomePlateHeightR.type;
          enumSet = Enums.PitchType;
          colorMapper = PitchTypeSeriesColorMapping;
          enabledHover = true;
          enabledMarkerClick = true;
          seriesMapper = Mappers.SeriesMappers.PlotlyScatterSeriesMapper;
          xAxisTitle = this.$vuetify.lang.t('$vuetify.horizontalLocation');
          yAxisTitle = this.$vuetify.lang.t('$vuetify.verticalLocation');
          break;

        case ChartTypeEnum.RELEASE_EXTENSION.type:
          componentName = 'ReleaseExtension';
          xConversion = conversionType.DISTANCE_SHORT;
          yConversion = conversionType.DISTANCE_SHORT;
          xPrecision = 1;
          enumSet = Enums.PitchType;
          colorMapper = PitchTypeSeriesColorMapping;
          enabledHover = true;
          enabledMarkerClick = true;
          seriesMapper = Mappers.SeriesMappers.PlotlyScatterSeriesMapper;
          xAxisTitle = this.$vuetify.lang.t('$vuetify.releaseExtension');
          yAxisTitle = this.$vuetify.lang.t('$vuetify.releaseHeight');
          break;

        case ChartTypeEnum.PITCH_MOVEMENT.type:
          componentName = 'PitchMovement';
          xConversion = Enums.PitchData.PFXX.type;
          yConversion = Enums.PitchData.PFXZ.type;
          enumSet = Enums.PitchType;
          colorMapper = PitchTypeSeriesColorMapping;
          enabledHover = true;
          enabledMarkerClick = true;
          seriesMapper = Mappers.SeriesMappers.PlotlyScatterSeriesMapper;
          xAxisTitle = this.$vuetify.lang.t('$vuetify.horizontalMovement');
          yAxisTitle = this.$vuetify.lang.t('$vuetify.verticalMovement');
          break;

        case ChartTypeEnum.CONTACT_REPORT.type:
        case ChartTypeEnum.CONTACT_REPORT_PITCHING.type:
          componentName = 'ContactReport';
          xConversion = Enums.PitchReprocessed.HomePlateSideR.type;
          yConversion = Enums.PitchReprocessed.HomePlateHeightR.type;
          enumSet = Enums.SwingDecision;
          colorMapper = SwingDecisionSeriesColorMapping;
          enabledHover = true;
          enabledMarkerClick = true;
          seriesMapper = Mappers.SeriesMappers.PlotlyScatterSeriesMapper;
          xAxisTitle = this.$vuetify.lang.t('$vuetify.horizontalLocation');
          yAxisTitle = this.$vuetify.lang.t('$vuetify.verticalLocation');
          break;

        default:
          throw new Error(`${this.viewName} not implemented`);
      }

      if (componentName) {
        component = () => import(`@/components/ui/charts/${componentName}.vue`);
      } else {
        component = componentName;
      }

      return {
        xConversion,
        yConversion,
        xPrecision,
        yPrecision,
        enumSet,
        colorMapper,
        seriesMapper,
        enabledHover,
        enabledMarkerClick,
        component,
        xAxisTitle,
        yAxisTitle,
        hoverTextFormatter,
      };
    },

    dataProvider() {
      switch (this.viewName) {
        case ChartTypeEnum.SPRAY_CHART.type:
          return Providers.UnifiedSprayChartSeriesProvider;

        case ChartTypeEnum.SPRAY_CHART_PITCHING.type:
          return Providers.SprayChartSeriesProvider;

        case ChartTypeEnum.STRIKE_ZONE.type:
          return Providers.UnifiedStrikeZoneChartSeriesProvider;

        case ChartTypeEnum.BATTER_PERFORMANCE.type:
          return Providers.HitTrackingChartSeriesProvider;

        case ChartTypeEnum.PITCHER_PERFORMANCE.type:
          return Providers.PitchTrackingChartSeriesProvider;

        case ChartTypeEnum.RELEASE_POINT.type:
          return Providers.ReleaseChartsSeriesProvider;

        case ChartTypeEnum.PITCH_LOCATION.type:
          return Providers.LocationChartSeriesProvider;

        case ChartTypeEnum.RELEASE_EXTENSION.type:
          return Providers.ExtensionChartsSeriesProvider;

        case ChartTypeEnum.PITCH_MOVEMENT.type:
          return Providers.MovementChartSeriesProvider;

        case ChartTypeEnum.BATTED_BALL_QUALITY.type:
          return Providers.QualityOfContactChartSeriesProvider;

        case ChartTypeEnum.CONTACT_REPORT.type:
          return Providers.ContactChartSeriesProvider;

        case ChartTypeEnum.CONTACT_REPORT_PITCHING.type:
          return Providers.ContactChartSeriesProvider;

        default:
          // TODO: maybe revert after implementation
          throw new Error('not implemented');
        // return () => {};
      }
    },

    performanceSettings() {
      let dataKey;
      let parameter;
      let unitSymbol;
      switch (this.viewName) {
        case ChartTypeEnum.BATTER_PERFORMANCE.type:
          dataKey = this.storeFilters.hitParameter || Enums.HitData.ExitSpeed.key;
          parameter = Providers.EnumValueProvider.getValue(dataKey, Enums.HitData);
          if (!this.mappedFilters.batterId) {
            this.$log.warn(`filter batterId must be set`);
            return {};
          }
          if (!parameter) {
            this.$log.warn(`parameter ${dataKey} couldn't be found`);
            return {};
          }
          unitSymbol = unitSymbolProvider(parameter.type, this.selectedUnitsSystem.system, 0);
          return {
            dataKey,
            dataName: parameter.name,
            unitSymbol,
            yConversion: parameter.type,
            relatedResultProvider: this.relatedResult,
          };

        case ChartTypeEnum.PITCHER_PERFORMANCE.type:
          dataKey = this.storeFilters.pitchParameter || Enums.PitchData.Speed.key;
          parameter = Providers.EnumValueProvider.getValue(dataKey, Enums.PitchData);
          if (!this.mappedFilters.pitcherId) {
            this.$log.warn(`filter pitcherId must be set`);
            return {};
          }
          if (!parameter) {
            this.$log.warn(`parameter ${dataKey} couldn't be found`);
            return {};
          }
          unitSymbol = unitSymbolProvider(parameter.type, this.selectedUnitsSystem.system, 0);
          return {
            dataKey,
            dataName: parameter.name,
            unitSymbol,
            yConversion: parameter.type,
            relatedResultProvider: this.relatedResult,
          };

        default:
          return {};
      }
    },

    watchedData() {
      if (!this.session) {
        return {
          filters: {},
          results: [],
        };
      }
      return {
        filters: this.mappedFilters,
        results: this.session.resultsv1,
      };
    },

    hoveredPointMedia() {
      if (this.loading || !this.session) {
        return undefined;
      }

      return this.pointData?.media;
    },

    hoveredPointData() {
      if (this.loading || !this.session) {
        return null;
      }

      return hoverPointDataProvider(
        this.pointData,
        this.chartSettings.xAxisTitle,
        this.chartSettings.yAxisTitle,
        this.session.mightHaveScoring,
        this.session.mightHaveNewScoringData
      );
    },

    allPairs() {
      return Providers.DataTableDataProvider(this.session.resultsv1).map(pair => {
        let { hit, pitch } = pair;
        pair.id = hit?.ResultID || pitch?.ResultID;
        return pair;
      });
    },

    /**
     * Needed for ABS to work properly
     * @returns
     */
    selectedAction() {
      if (this.$route.params.action && this.allPairs?.length) {
        let out = this.allPairs.find(m => m.id == this.$route.params.action);

        if (out) {
          let mediaArr = [];
          let { hit, pitch } = out;

          if (pitch?.Media?.length) {
            mediaArr = mediaArr.concat(pitch.Media);
          }
          if (hit?.Media?.length) {
            mediaArr = mediaArr.concat(hit.Media);
          }

          /** @type {Media[]} */
          let videos = mediaArr.map(this.session.mapMediaForResult.bind(this.session)).filter((m) => m.isVideo);

          return {
            id: out.id,
            results: out,
            videos,
          };
        }
      }
      return null;
    },

    bottomSheetVisible() {
      return !!(this.$route.params.action && this.selectedAction);
    },

    isTooltipVisible() {
      return this.hoveredPointData && this.hasSeries;
    }
  },

  methods: {
    seriesSI() {
      return this.$options.seriesSI;
    },

    viewGuard() {
      if (definedViews.includes(this.viewName)) {
        return true;
      }

      throw new Error(`View ${this.viewName} not implemented.`);
    },
    relatedResult(id) {
      return this.session.MatchingResult(id);
    },
    seriesGuard() {
      switch (this.viewName) {
        case ChartTypeEnum.SPRAY_CHART.type:
          return !this.mappedFilters.batterIds;
        case ChartTypeEnum.SPRAY_CHART_PITCHING.type:
          return !this.mappedFilters.pitcherIds;
        case ChartTypeEnum.STRIKE_ZONE.type:
        case ChartTypeEnum.BATTER_PERFORMANCE.type:
          return !this.mappedFilters.batterId;

        case ChartTypeEnum.CONTACT_REPORT.type:
          return !this.mappedFilters.batterIds;

        case ChartTypeEnum.CONTACT_REPORT_PITCHING.type:
          return !this.mappedFilters.pitcherIds;

        case ChartTypeEnum.PITCHER_PERFORMANCE.type:
        case ChartTypeEnum.RELEASE_POINT.type:
        case ChartTypeEnum.PITCH_LOCATION.type:
        case ChartTypeEnum.RELEASE_EXTENSION.type:
        case ChartTypeEnum.PITCH_MOVEMENT.type:
          return !this.mappedFilters.pitcherId;

        default:
          return false;
      }
    },

    provideSeries(val, oldVal) {
      this.loading = true;
      this.hasSeries = false;
      this.pointData = {};

      this.viewGuard();

      if (this.seriesGuard() || val.results.length == 0) {
        this.$options.seriesSI = null;
        this.loading = false;
        return;
      }
      switch (this.viewName) {
        case ChartTypeEnum.CONTACT_REPORT_PITCHING.type:
        case ChartTypeEnum.CONTACT_REPORT.type:
          this.$options.seriesSI = this.dataProvider(
            this.session.resultsv1,
            undefined,
            this.mappedFilters,
            this.session.mightHaveNewScoringData
          );
          this.hasSeries = this.$options.seriesSI.categories.length > 0;
          this.providerCounter++;
          break;

        case ChartTypeEnum.BATTER_PERFORMANCE.type:
          this.$options.seriesSI = this.dataProvider(
            this.session.resultsv1,
            undefined,
            this.performanceSettings.dataKey || Enums.HitData.ExitSpeed.key,
            this.mappedFilters
          );
          this.hasSeries = this.$options.seriesSI.categories.length > 0;
          this.providerCounter++;
          break;

        case ChartTypeEnum.PITCHER_PERFORMANCE.type:
          this.$options.seriesSI = this.dataProvider(
            this.session.resultsv1,
            undefined,
            this.performanceSettings.dataKey || Enums.PitchData.Speed.key,
            this.mappedFilters
          );
          this.hasSeries = this.$options.seriesSI.categories.length > 0;
          this.providerCounter++;
          break;
        default:
          this.$options.seriesSI = this.dataProvider(this.session.resultsv1, undefined, this.mappedFilters);
          this.hasSeries = this.$options.seriesSI.categories.length > 0;
          this.providerCounter++;
      }
      this.loading = false;
    },

    hoverHandler(event) {
      if (!this.session || !this.$options.seriesSI || !event || !event.hasOwnProperty('points')) {
        // todo -debug log
        return;
      }

      // lets focus on first axes...
      const mouseX = event.hasOwnProperty('xvals') ? event.xvals[0] : undefined;
      const mouseY = event.hasOwnProperty('yvals') ? event.yvals[0] : undefined;

      let closestPoint = event.points[0];

      if (event.points.length > 1 && typeof mouseX !== 'undefined' && typeof mouseY !== 'undefined') {
        // it can happen that we have more points around mouseX and mouseY - just imagine overlapping points from multiple series
        const pointToMouse = event.points
          // get numbers only
          .filter(p => !Number.isNaN(p.x) && !Number.isNaN(p.y))
          // calculate distance
          .map( p => Math.sqrt(
            Math.pow(Math.abs(mouseX - p.x) + Math.abs(mouseY - p.y), 2)
          ));

        if (pointToMouse.length) {
          // search for smallest value (first item after sorting)
          closestPoint = event.points[pointToMouse.indexOf([].concat(pointToMouse).sort()[0])];
        }
      }

      if (!closestPoint) {
        // todo - consider throwing here ? or log only
        return;
      }

      const resultId = closestPoint.customdata;
      if (!resultId) {
        // todo - debug log
        return;
      }

      const matchingResult = this.session.MatchingResult(resultId);

      if (!matchingResult) {
        throw new TypeError(`Matching result for ResultId: ${resultId} was not found.`);
      }

      const hitResult = this.session.getHitForMatchingResult(resultId);
      const pitchResult = this.session.getPitchForMatchingResult(resultId);
      const pitcher = this.session.getPitcher(pitchResult);
      const batter = this.session.getBatter(hitResult, pitchResult);

      const initialSeriesIndex = this.$options.seriesSI.findIndex(s => s.getSources().includes(resultId));
      if (initialSeriesIndex < 0) {
        throw new TypeError(`Couldn't determine index from initial series for result ${resultId}.`);
      }

      // it can throw as well... let's assume that points are in the same order
      const rawPoint = this.$options.seriesSI.getValue(initialSeriesIndex, closestPoint.pointIndex);//, Enums.SeriesDimension.X

      let hitResultMedia;
      let pitchResultMedia;

      if (hitResult) {
        ({ Media: hitResultMedia } = hitResult);
      }

      if (pitchResult) {
        ({ Media: pitchResultMedia } = pitchResult);
      }

      let media = pitchResultMedia || hitResultMedia;
      if (media && media.length) {
        media = media.map(this.session.mapMediaForResult.bind(this.session)).filter(m => m.isVideo);
      }

      // todo - consider passing ENTIRE session model to it, so it will be able to get matching hit / pitch  and will know about scoring...
      this.pointData = tooltipDataProvider(
        rawPoint,
        this.session.mightHaveScoring,
        this.session.mightHaveNewScoringData,
        hitResult,
        pitchResult,
        pitcher,
        batter,
        this.selectedUnitsSystem.system,
        this.chartSettings.xConversion,
        this.chartSettings.yConversion,
        this.chartSettings.xPrecision,
        this.chartSettings.yPrecision,
        media ? media : []
      );
    },

    markerClickHandler(event) {
      const resultId = event.points[0].customdata;

      const hitResult = this.session.getHitForMatchingResult(resultId);
      const pitchResult = this.session.getPitchForMatchingResult(resultId);

      const hitResultId = hitResult ? hitResult.Data.GUID : false;
      const pitchResultId = pitchResult ? pitchResult.Data.GUID : false;

      let guid = hitResultId || pitchResultId;
      if (guid) {
        this.$router.push({ name: 'session.table', hash: `#${sanitizeGuid(guid)}` });
      }
    },

    getCarry(resultId) {
      {
        if (!resultId) {
          return;
        }

        const hitResult = this.session.getHitForMatchingResult(resultId);
        if (!hitResult) {
          this.$log.debug(`Hit Result for ResultId: ${resultId} was not found.`);
          return '-';
        }

        const hitResultData = hitResult[Enums.ResultData.Data.key];
        let carryDistance = hitResultData[Enums.HitData.CarryDistance.key];
        if (typeof carryDistance !== 'number') {
          const landingSideR = hitResultData[Enums.HitReprocessed.LandingSideR.key];
          const LandingDistanceR = hitResultData[Enums.HitReprocessed.LandingDistanceR.key];
          if (typeof landingSideR !== 'undefined' && LandingDistanceR) {
            carryDistance = Math.sqrt(Math.pow(landingSideR, 2) + Math.pow(LandingDistanceR, 2));
          }
        }

        const carryDistanceFormatted = unitsConverter
        .convertType(carryDistance, Enums.HitData.CarryDistance.type, this.selectedUnitsSystem.system)
        .formatWithSymbol('-', 2);
        return carryDistanceFormatted;
      }
    }
  },

  watch: {
    watchedData: {
      handler: 'provideSeries',
      immediate: true,
      deep: true,
    },
    selectedUnitsSystem: {
      handler(val, oldVal) {
        this.pointData = {};
      },
      deep: true
    },
  },
};
